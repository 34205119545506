import { type RouteConfig } from 'vue-router'
import { useCms } from '/~/composables/cms/use-cms'

export default [
  {
    path: '/',
    name: 'home',
    component: () => import('/~/views/home/home.vue'),
    props: true,
    meta: {
      title: 'Home',
      page: 'home',
      footer: true,
    },
    beforeEnter(_, __, next) {
      const { cmsRedirects } = useCms()
      const { homeRedirect = {} } = cmsRedirects.value
      const { enabled = false, value: name, params = {} } = homeRedirect

      if (enabled) {
        next({
          name,
          params,
        })
      } else {
        next()
      }
    },
  },
  {
    path: '/checkout',
    meta: {
      title: 'Checkout',
      hideBottomBar: true,
      toolbar: false,
    },
    component: () => import('/~/views/checkout/checkout.vue'),
    children: [
      {
        path: '',
        name: 'purchase-checkout',
        component: () =>
          import('/~/views/checkout/views/checkout/checkout.vue'),
        meta: {
          title: 'Checkout',
          toolbar: false,
          index: 0,
        },
      },
      {
        path: 'billing',
        name: 'purchase-checkout-billing',
        props: true,
        component: () =>
          import('/~/views/checkout/views/addresses/addresses.vue'),
        meta: {
          title: 'Checkout Billing',
          toolbar: false,
          type: 'billing',
          index: 1,
        },
      },
      {
        path: 'shipping',
        name: 'purchase-checkout-shipping',
        props: true,
        component: () =>
          import('/~/views/checkout/views/addresses/addresses.vue'),
        meta: {
          title: 'Checkout Shipping',
          toolbar: false,
          type: 'shipping',
          index: 1,
        },
      },
      {
        path: 'payment-methods',
        name: 'purchase-checkout-payment-methods',
        component: () =>
          import('/~/views/checkout/views/payment-methods/payment-methods.vue'),
        meta: {
          title: 'Checkout Payment Methods',
          toolbar: false,
          index: 1,
        },
      },
    ],
  },
  {
    path: '/checkout/confirmation/:orderId',
    name: 'purchase-checkout-confirmation',
    component: () =>
      import('/~/views/checkout/views/confirmation/checkout-confirmation.vue'),
    meta: {
      title: 'Checkout Confirmation',
      toolbar: false,
      home: 'home',
    },
    props: true,
  },
  {
    path: '/checkout/payment-failed',
    name: 'purchase-checkout-payment-failed',
    component: () =>
      import('/~/views/checkout/views/payment-failed/payment-failed.vue'),
    meta: {
      title: 'Checkout Payment Failed',
      toolbar: false,
      home: 'home',
    },
    props: true,
  },
  {
    path: '/quick-buy',
    meta: {
      title: 'Express checkout',
      hideBottomBar: true,
      toolbar: false,
      quickBuy: true,
    },
    component: () => import('/~/views/quick-buy/checkout.vue'),
    children: [
      {
        path: '',
        name: 'quick-buy-checkout',
        component: () =>
          import('/~/views/quick-buy/views/checkout/checkout.vue'),
        meta: {
          title: 'Express Checkout',
          toolbar: false,
          index: 0,
          quickBuy: true,
        },
      },
      {
        path: 'billing',
        name: 'quick-buy-billing',
        props: true,
        component: () =>
          import('/~/views/quick-buy/views/addresses/addresses.vue'),
        meta: {
          title: 'Quick Buy Billing',
          toolbar: false,
          type: 'billing',
          index: 1,
          quickBuy: true,
        },
      },
      {
        path: 'shipping',
        name: 'quick-buy-shipping',
        props: true,
        component: () =>
          import('/~/views/quick-buy/views/addresses/addresses.vue'),
        meta: {
          title: 'Quick Buy Shipping',
          toolbar: false,
          type: 'shipping',
          index: 1,
          quickBuy: true,
        },
      },
      {
        path: 'payment-methods',
        name: 'quick-buy-payment-methods',
        component: () =>
          import(
            '/~/views/quick-buy/views/payment-methods/payment-methods.vue'
          ),
        meta: {
          title: 'Quick Buy Payment Methods',
          toolbar: false,
          index: 1,
          quickBuy: true,
        },
      },
    ],
  },
  {
    path: '/quick-buy/confirmation/:orderId',
    name: 'quick-buy-confirmation',
    component: () =>
      import('/~/views/quick-buy/views/confirmation/checkout-confirmation.vue'),
    meta: {
      title: 'Express Checkout Confirmation',
      toolbar: false,
      home: 'home',
    },
    props: true,
  },
  {
    path: '/help',
    name: 'help',
    meta: {
      title: 'Help',
      footer: true,
    },
    component: () => import('/~/views/help/help.vue'),
  },
  {
    path: '/activity',
    name: 'activity',
    component: () => import('/~/views/activity/list/activity.vue'),
    meta: {
      title: 'Activity',
      page: 'activity',
    },
  },
  {
    path: '/activity/:id',
    name: 'activity-details',
    props: true,
    meta: {
      title: 'Activity Details',
    },
    component: () => import('/~/views/activity/details/activity-details.vue'),
  },
  {
    path: '/automotive',
    name: 'automotive',
    meta: {
      title: 'Automotive',
      footer: true,
    },
    component: () => import('/~/views/automotive/automotive.vue'),
  },
  {
    path: '/benefit-pocket',
    name: 'benefit-pocket',
    meta: {
      title: 'Benefit Pocket',
      footer: true,
      page: 'benefit-pocket',
    },
    component: () => import('/~/views/benefit-pocket/benefit-pocket.vue'),
  },
  {
    path: '/groups-community-hub-offer/:id',
    name: 'groups-community-hub-offer',
    props: true,
    component: () =>
      import(
        '/~/extensions/community-hub/templates/bupa/views/groups/community-hub-groups-offer.vue'
      ),
  },
] as Array<RouteConfig>
